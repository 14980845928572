/*---- VARIABLES ------*/

$brand-color: #FF6363;

@mixin transition-style($transition) {
    -webkit-transition: $transition;
    -o-transition:  $transition;
    -ms-transition:  $transition;
    -moz-transition:  $transition;
    transition:  $transition;
}

@mixin animation($transition) {
    -webkit-animation: $transition;
    -moz-animation: $transition;
    -ms-animation: $transition;
    -o-animation: $transition;
    animation: $transition;
}

/*--- CHANGE BG COLOR ---*/

@-webkit-keyframes changeBgColor {
    0% {
        background-color: #e17055;
    }
    25% {
        background-color: #1abc9c;
    }
    50% {
        background-color: #2980b9;
    }
    75% {
        background-color: #9b59b6;
    }
    100% {
        background-color: #e17055;
    }
}

/*---- BOUNCE ANIMATION ------*/

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateX(0);
    }
    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

/*---- BLINK ANIMATION ------*/

@keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: white;
    }
}

@-moz-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: white;
    }
}

@-webkit-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: white;
    }
}

@-ms-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: white;
    }
}

@-o-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: white;
    }
}